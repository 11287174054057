*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

*|*:-webkit-any-link {
    cursor: pointer !important;
    text-decoration: none !important;
}

*|*:-moz-any-link {
    cursor: pointer !important;
    text-decoration: none !important;
}

*|*:any-link {
    cursor: pointer !important;
    text-decoration: none !important;
}

img{
    max-width: 100%;
    margin: auto;
    display: block;
}