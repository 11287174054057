@import '../helpers/variables.scss';
@import '../components/_font.scss';

#Firebase{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    h1{
        font-size: 30px;
        font-family: $titre;
        font-weight: $bold;
        // margin: auto;
        text-align: center;
    }
    
    label, p {
        font-family: Labeur_light;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        display: inline-block;
        // margin: auto;
        text-align: center;
    }

    input{
        margin-top: 3%;
    }

    #firstinput{
        padding-bottom: 5vh;
    }

    #secondinput{
        padding-bottom: 5vh;
    }

    #contentFirebase{
        padding-bottom: 15vh;
    }

    #btn-firebase2{
        opacity: .5;
        padding-top: 1vh!important;
    }

    .next_introduction{
        padding-top: 3vh!important;
        font-family: Labeur_Light;
        cursor: pointer;
        display: block;
        margin: auto!important;
        color: #000000;
        text-align: center;
        padding: 1% 3%;
        margin-right: 1.5%;
        opacity: 0.5;
        font-size: 20px;
        font-weight: $regular!important;
    }

    #firebaseConnexion{
        margin: auto!important;
    }
}
