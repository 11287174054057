@import '../helpers/variables.scss';
@import '../components/_font.scss';


#SwipeRecompense{
    max-width: 92%!important;
}


.slider_onboarding {
    position: relative;
    cursor: pointer;
    background: #FBFBFB;
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 10vh;

    img{
        max-width: 80%!important;
    }

    h2 {
        font-family: $titre;
        font-size: 30px;
        font-weight: $bold;
        padding: 5vh 0vh 3vh 0vh;
    }

    p {
        font-size: 19px;
        width: 70%;
        text-align: center;
        margin: auto;
        display: block;
        font-family: Labeur_Light;
        padding-bottom: 5vh;
    }

    b{
        font-family: Labeur_Bold;
        font-weight: $bold;
    }

    a {
        font-family: Labeur_Light;
        cursor: pointer;
        display: block;
        margin: auto!important;
        color: #000000;
        text-align: center;
        padding: 1% 3%;
        margin-right: 1.5%;
        opacity: 0.5;
        font-size: 20px;
        font-weight: $regular!important;
    }

    a:first-child {
        padding-right: 5%;
    }
}

#paginationreplace{
    position: absolute;
    bottom: 13vh;
    margin: auto;
    width: 100%;
    
    
    span{
        border-radius: 3px;
        background-image: linear-gradient(to right, #e76b61, #dc6795);
        display: inline-block;
        height: 3px;
    }

    .pr-active{
        width: 45px!important;
    }

    .pr-mid{
        margin: 0% 3%;
    }

    .paginationreplace{
        width: 15px;
    }
}

.next_intro{
    position: absolute;
    bottom: 5vh;
    margin: auto;
    width: 100%;
}
