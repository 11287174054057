@import '../helpers/variables.scss';
@import '../components/_font.scss';

#isMobile{
    background-color: red;
    background-image: url(../../img/phone-background2.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100%;
    padding-left: 15%;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;

    h1{
        font-family: $titre;
        font-size: 60px;
        font-weight: $bold;
        color: white;
    }

    hr{
        height: 5px;
        border: none;
        background: linear-gradient(90deg, #dc6795 35%, #e76b61 100%);
        width: 15%;
        margin: 2vh 0vh 10vh 0vh;
    }

    p{
        color: white;
        font-family: Labeur_Light;
        font-size: 20px;
        padding-bottom: 3vh;
        width: 40%;
    }
    
    a{
        padding-left: 3%;
        padding-right: 3%;
        width: 20%!important;
        padding: 1% 1%!important;
    }
    
    b{
        font-family: Labeur_Bold;
        font-weight: $bold;
    }
}