#loader{
    width: 100%;
    height: 100vh;
    background-color: #fbfbfb;
    background-image: url('../../img/yunt.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute; 
    top: 50%; left: 50%; 
    transform: translate(-50%, -50%); 
}